var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      staticClass: "work-experience-modal",
      attrs: {
        title: _vm.title,
        height: _vm.auto,
        width: "40%",
        "show-zoom": "",
        resize: "",
        "lock-view": false,
        mask: false,
        position: { top: "5%", left: "30%" },
      },
      on: { close: _vm.handleCloseModal },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["tenant:iot:workExperience:add"],
                  expression: "['tenant:iot:workExperience:add']",
                },
              ],
              attrs: { type: "primary", disabled: _vm.form.isEdit },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _vm.items.length === 0
        ? _c("a-card", { staticClass: "item" }, [_c("a-empty")], 1)
        : _vm._l(_vm.items, function (item, index) {
            return _c(
              "a-card",
              {
                key: index,
                ref: "itemRef",
                refInFor: true,
                staticClass: "item",
              },
              [
                !item.isEdit
                  ? _c("div", { staticClass: "container" }, [
                      _c(
                        "div",
                        { staticClass: "edit-btn" },
                        [
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:iot:workExperience:edit"],
                                  expression:
                                    "['tenant:iot:workExperience:edit']",
                                },
                              ],
                              attrs: { disabled: _vm.form.isEdit },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(item, index)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:iot:workExperience:remove"],
                                  expression:
                                    "['tenant:iot:workExperience:remove']",
                                },
                              ],
                              attrs: { disabled: _vm.form.isEdit },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(item, index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "row" }, [
                        _c("span", [_vm._v("公司名称：")]),
                        _c("span", { staticClass: "with-btn" }, [
                          _vm._v(_vm._s(item.company)),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("span", [_vm._v("在职时间：")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(item.onTheJobBeginTime) +
                              "~" +
                              _vm._s(item.onTheJobEndTime)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("span", [_vm._v("最终职务：")]),
                        _c("span", [_vm._v(_vm._s(item.finalPosition))]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("span", [_vm._v("工作内容：")]),
                        _c("span", [_vm._v(_vm._s(item.jobDescription))]),
                      ]),
                    ])
                  : _vm._e(),
                item.isEdit
                  ? _c(
                      "div",
                      { staticClass: "container-edit" },
                      [
                        _c(
                          "a-form-model",
                          {
                            ref: "form",
                            refInFor: true,
                            attrs: { model: _vm.form, rules: _vm.rules },
                          },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "公司名称", prop: "company" } },
                              [
                                _c("a-input", {
                                  attrs: {
                                    placeholder: "请输入公司名称",
                                    allowClear: "",
                                  },
                                  model: {
                                    value: _vm.form.company,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "company", $$v)
                                    },
                                    expression: "form.company",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "在职时间",
                                  prop: "onTheJobBeginTime",
                                },
                              },
                              [
                                _c("a-month-picker", {
                                  staticClass: "begin-time",
                                  attrs: {
                                    placeholder: "请选择",
                                    "value-format": "YYYY-MM",
                                    format: "YYYY-MM",
                                    allowClear: false,
                                  },
                                  model: {
                                    value: _vm.form.onTheJobBeginTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "onTheJobBeginTime",
                                        $$v
                                      )
                                    },
                                    expression: "form.onTheJobBeginTime",
                                  },
                                }),
                                _vm._v(" 到 "),
                                _c("br"),
                                _c("month-ex", {
                                  staticClass: "end-time",
                                  model: {
                                    value: _vm.form.onTheJobEndTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "onTheJobEndTime", $$v)
                                    },
                                    expression: "form.onTheJobEndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "最终职务",
                                  prop: "finalPosition",
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: {
                                    placeholder: "请输入最终职务",
                                    allowClear: "",
                                  },
                                  model: {
                                    value: _vm.form.finalPosition,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "finalPosition", $$v)
                                    },
                                    expression: "form.finalPosition",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "工作内容",
                                  prop: "jobDescription",
                                },
                              },
                              [
                                _c("a-textarea", {
                                  attrs: {
                                    placeholder: "请输入工作内容",
                                    "auto-size": { minRows: 6 },
                                    allowClear: "",
                                  },
                                  model: {
                                    value: _vm.form.jobDescription,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "jobDescription", $$v)
                                    },
                                    expression: "form.jobDescription",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "footer", attrs: { id: "abc" } },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSave(item, index)
                                  },
                                },
                              },
                              [_vm._v("保存")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCancel(item, index)
                                  },
                                },
                              },
                              [_vm._v("取消")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
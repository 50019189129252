import request from '@/utils/request'

// 查询工作经历列表
export function listWorkExperience(query) {
  return request({
    url: '/iot/workExperience/list',
    method: 'get',
    params: query
  })
}

// 获取工作经历详细信息
export function getWorkExperience(id) {
  return request({
    url: '/iot/workExperience/' + id,
    method: 'get'
  })
}

// 新增工作经历
export function addWorkExperience(data) {
  return request({
    url: '/iot/workExperience',
    method: 'post',
    data: data
  })
}

// 修改工作经历
export function updateWorkExperience(data) {
  return request({
    url: '/iot/workExperience',
    method: 'put',
    data: data
  })
}

// 删除工作经历
export function delWorkExperience(id) {
  return request({
    url: '/iot/workExperience/' + id,
    method: 'delete'
  })
}

<template>
  <vxe-modal
    v-model="open"
    class="work-experience-modal"
    :title="title"
    :height="auto"
    width="40%"
    @close="handleCloseModal"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :position="{ top: '5%', left: '30%' }"
  >
    <div class="header"><a-button type="primary" @click="handleAdd" :disabled="form.isEdit" v-hasPermi="['tenant:iot:workExperience:add']">添加</a-button></div>
    <a-card v-if="items.length === 0" class="item">
      <a-empty />
    </a-card>
    <a-card v-else ref="itemRef" v-for="(item, index) in items" :key="index" class="item">
      <div class="container" v-if="!item.isEdit">
        <div class="edit-btn">
          <a-button @click="handleEdit(item, index)" :disabled="form.isEdit" v-hasPermi="['tenant:iot:workExperience:edit']">编辑</a-button>
          <a-button @click="handleDelete(item, index)" :disabled="form.isEdit" v-hasPermi="['tenant:iot:workExperience:remove']">删除</a-button>
        </div>
        <div class="row">
          <span>公司名称：</span>
          <span class="with-btn">{{ item.company }}</span>
        </div>
        <div class="row">
          <span>在职时间：</span>
          <span>{{ item.onTheJobBeginTime }}~{{ item.onTheJobEndTime }}</span>
        </div>
        <div class="row">
          <span>最终职务：</span>
          <span>{{ item.finalPosition }}</span>
        </div>
        <div class="row">
          <span>工作内容：</span>
          <span>{{ item.jobDescription }}</span>
        </div>
      </div>

      <div class="container-edit" v-if="item.isEdit">
        <a-form-model ref="form" :model="form" :rules="rules">
          <a-form-model-item label="公司名称" prop="company">
            <a-input v-model="form.company" placeholder="请输入公司名称" allowClear />
          </a-form-model-item>
          <a-form-model-item label="在职时间" prop="onTheJobBeginTime">
            <a-month-picker placeholder="请选择" class="begin-time" v-model="form.onTheJobBeginTime" value-format="YYYY-MM" format="YYYY-MM" :allowClear="false" /> 到
            <br />
            <month-ex class="end-time" v-model="form.onTheJobEndTime" />
          </a-form-model-item>
          <a-form-model-item label="最终职务" prop="finalPosition">
            <a-input v-model="form.finalPosition" placeholder="请输入最终职务" allowClear />
          </a-form-model-item>
          <a-form-model-item label="工作内容" prop="jobDescription">
            <a-textarea v-model="form.jobDescription" placeholder="请输入工作内容" :auto-size="{ minRows: 6 }" allowClear />
          </a-form-model-item>
        </a-form-model>
        <div id="abc" class="footer"><a-button type="primary" @click="handleSave(item, index)">保存</a-button> <a-button @click="handleCancel(item, index)">取消</a-button></div>
      </div>
    </a-card>
  </vxe-modal>
</template>
<script>
import MonthEx from '../MonthEx'
import { listWorkExperience, addWorkExperience, updateWorkExperience, delWorkExperience } from '@/api/iot/workExperience'
import { Empty } from 'ant-design-vue'
export default {
  name: 'WorkExperience',
  components: { MonthEx, AEmpty: Empty },
  data() {
    return {
      open: false,
      employeeId: null,
      title: null,
      form: {
        employeeId: null,
        company: null,
        onTheJobBeginTime: null,
        onTheJobEndTime: null,
        finalPosition: null,
        jobDescription: null
      },
      items: [],
      rules: {
        company: [{ required: true, message: '公司名称不能为空', trigger: 'blur' }],
        onTheJobBeginTime: [{ required: true, message: '在职时间不能为空', trigger: 'change' }],
        finalPosition: [{ required: true, message: '最终职务不能为空', trigger: 'blur' }],
        jobDescription: [{ required: true, message: '工作内容不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {},
  mounted() {
    this.setModalBoxStyle()
  },
  methods: {
    setModalBoxStyle() {
      const e = document.querySelector('.work-experience-modal .vxe-modal--box')
      if (e) {
        e.style.maxHeight = '90%'
      }
    },
    handleUpdate(row, ids) {
      this.form.isEdit = false
      this.employeeId = row.id
      this.title = row.name + '-工作经历'
      const queryParam = { employeeId: row.id }
      listWorkExperience(queryParam).then((response) => {
        this.items = response.rows
        this.open = true
        if (this.items.length === 0) {
          // this.handleAdd()
        }
      })
    },
    handleCloseModal() {
      this.open = false
    },
    handleEdit(item, index) {
      item.isEdit = true
      this.form = { ...item }
      this.$nextTick(() => {})
    },
    handleSave(item, index) {
      if (this.form.onTheJobEndTime !== '至今') {
        if (this.form.onTheJobEndTime < this.form.onTheJobBeginTime) {
          this.$warningEx('在职结束时间不能小于开始时间！')
          return
        }
      }
      this.$refs.form[0].validate((valid) => {
        if (valid) {
          // 保存成功要返回当前数据库记录
          if (this.form.id) {
            updateWorkExperience(this.form).then((response) => {
              this.form.isEdit = false
              item.isEdit = false
              Object.assign(item, response.data)
            })
          } else {
            addWorkExperience(this.form).then((response) => {
              this.form.isEdit = false
              item.isEdit = false
              Object.assign(item, response.data)
            })
          }
        }
      })
    },
    handleCancel(item, index) {
      // 新记录取消直接删除
      if (!this.items[index].id) {
        this.form.isEdit = false
        this.items.splice(index, 1)
        return
      }
      item.isEdit = false
      this.form.isEdit = false
    },
    handleAdd() {
      const form = {
        employeeId: this.employeeId,
        company: null,
        jobDescription: null,
        onTheJobBeginTime: null,
        onTheJobEndTime: '至今',
        finalPosition: null,
        isEdit: true
      }
      this.form = { ...form }
      this.items.unshift({ ...this.form })
    },
    handleDelete(item, index) {
      this.$confirm({
        title: '确定要删除这条记录吗？',
        zIndex: 99999,
        onOk: () => {
          delWorkExperience(item.id).then((response) => {
            this.items.splice(index, 1)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  height: auto !important;
  margin-bottom: 14px;
}
.header {
  height: 44px !important;
}
.container,
.container-edit {
  position: relative;
  display: flex;
  flex-direction: column;
  // border-top: 1px solid #ddd;
  height: auto !important;
  padding: 0;
  .row {
    display: flex;
    padding: 5px 0;
    span:first-child {
      min-width: 70px;
    }
    .with-btn {
      padding-right: 150px;
    }
  }
  .edit-btn {
    position: absolute;
    top: 0;
    right: 0;
    button:first-child {
      margin-right: 5px;
    }
  }
  .ant-form-item {
    margin-bottom: 5px !important;
  }
  .footer {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    button {
      margin-right: 10px;
    }
  }
  .begin-time {
    margin-right: 8px;
  }
  .end-time {
    margin-left: 8px;
  }
}
.container:hover,
.container-edit {
  // border: 1px solid #ff6000 !important;
}
</style>

<style lang="less">
.work-experience-modal {
  .container-edit {
    .ant-form-item {
      display: flex;
      .ant-form-item-label {
        flex: 0 0 80px;
      }
      .ant-form-item-control-wrapper {
        flex: 1;
      }
    }

    .ant-form-item-children {
      display: flex;
      align-items: center;
    }
  }
  .vxe-modal--body {
    min-height: 446px;
  }
  .vxe-modal--content {
    padding-bottom: 0;
  }
  .ant-empty-image {
    height: 297px;
  }
}
</style>
